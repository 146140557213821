//font
$font_0: PT Sans;
$font-1:'PT Sans', sans-serif;
$font-2:'Raleway', sans-serif;
$font-3:'Montserrat', sans-serif;
$font-4:'Roboto', sans-serif ;
$font-5:'Cabin', sans-serif;
$font-6:'Aclonica', sans-serif;
$font-7:'Balsamiq Sans', cursive;
$font-8:'Roboto', sans-serif;
$font-9:'Libre Baskerville', serif;
$font-10:'Sriracha', cursive;
$font-11:'Cabin', sans-serif;
$font-12: 'Poppins', sans-serif;
$font-13:'Merriweather';
$font-14:'Alatsi', sans-serif;
$font-15:'Courgette', cursive;
$font-16:'Sansita Swashed', cursive;
$font-17:'Chau Philomene One', sans-serif;
$font-18:'Days One', sans-serif;
$font-19:'Merienda', cursive;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;
$font-work-sans: work-Sans;
$font-nunito: Nunito;
$font-serif: sans-serif;

//color
$theme-color1:#ff6000;
$theme-color2:#ffa800;
$theme-color3:#33b54c;
$theme-color4:#f85357;
$theme-color5:#f6d51f;
$body-back:#f2f2f2;
$bg-light : $body-back;
$bg-light1:#f9f9f9;
$body-text:#777;
$black: #000000;
$black1:#303030;
$white:#ffffff;
$white1:#f1f5f8 ;
$dark:#444444;
$orange:#ffa800 ;
$collection-hover:rgba(255,96,0,0.2);
$light-gray:#aaaaaa;
$gray1:#f1f1f1;
$gray-2:#999999;
$gray-3:#8d8d8d;
$gray-4:#acacac;
$gray-5:#eeeeee;
$gray-6:#8a8a8a;
$gray-7:#939b9e;
$gray-8:#f1f4f7;
$light-border:#dddddd;
$icon-color:#777777;
$hot-deal:#eef3f4;
$hot-deal-1:#f2f2f2;
$rating-star:#ffa800;
$follow-back:#f7f7f7;
$img-border:#f0f0f0;
$contact-back:#14286a;
$gift-back :#327fa3;
$blue:#d0edff;
$pink:#f1e7e6;
$gray:#bfbfbf;
$red:#ff0000;
$font-color:#333333;
$light-green:#4ead4e;
$light-green1:rgba($theme-color2, 0.45);
$bredcumb-main:#e8e8e8;
$category-back:#e7e7e9;
$landing-dark:#282828 ;
$landing-back:#fffbf9 ;
$chocolate:#ae745f;
$jade:#6f9d34;
$bluegreen:#2da6a4;
$apricot:#e9b77c;


$bg-theme1:#4aa4d3;
$bg-theme2:#d71093;
$bg-theme3:#d49723;
$bg-theme4:#f4b139;
$bg-theme5:#3f465c;
$bg-theme6:#679e13;
$bg-theme7:#35a7b1;
$bg-theme8:#1136b2;
$bg-theme9:#f72538;
$bg-theme10: #01917e;

$fbcolor:#39539a;
$twtcolor:#2fb0f6;
$indidcolor:#2164f3;
$printcolor:#de0422;
$instacolor:#c42253;





// slider //
$rich-gold:#eddbd1;
$solitud:#dfe9f3 ;
$gaynsboro:#e7e7e7 ;
$hawkesh-blue:#f0dec9 ;
$gray93:#ededed;



// dark layout //
$dark-layout:#1b1b1b;
$light-layout: #262626;
$light-layout1:#2d2d2d; 
$dark-border : #3c3c3c;
$dark-link:#cbcbcb;
$font-layout-1:#f1f3f5;
$font-layout-2:#eaedef;
$font-layout-3:#e7eaec;
$font-layout-4:#dee2e6;
$font-layout-5:#cfd4da;


$white-1:#f1f3f5;
$white-2:#eaedef;
$white-3:#e7eaec;
$white-4:#dee2e6;
$white-5:#cfd4da;

// Theme colors variables
$primary-color: #00baf2;
$secondary-color: #314da7 ;
$success-color: #11d89c ;
$info-color   : #00a8ff  ;
$warning-color: #00ced1;
$danger-color: #e26767 ;
$light-color   : #f8f8f9 ;
$semi-dark  :#aaaaaa;
$light-semi-gray   :#eff0f1;
$light-gray   :#e8ebf2;
$dark-gray 	:#898989;
$dark-color   : #2a3142;
$gray-60   :#999999 ;
$transparent-color: transparent;
$auth-bg-color:#fafafa;
$light : #f6f6f6;
$light-text: #999;


// Tables settings
$horizontal-border-color:    #dee2e6;
$table-b-margin:             0px;
$table-footer-font-weight:   bold;
$table-inverse-color:        $white;
$table-hover-color:          $light;
$table-padding:              0.75rem;
$table-xl-padding:           1.25rem 2rem;
$table-xl-font:              120%;
$table-lg-padding:           .9rem 2rem;
$table-lg-font:              110%;
$table-de-padding:           .75rem 2rem;
$table-de-font:              100%;
$table-sm-padding:           .5rem 2rem;
$table-sm-font:              90%;
$table-xs-padding:           0.2rem 2rem;
$table-xs-font:              80%;
$horizontal-border-color:    #dee2e6;
$horizontal-padding:         .75rem 2rem;
$table-inverse-bg-color:     #292b2c;


// General variables
$theme-font-color: #1b252a;
$theme-body-font-color: #313131;
$theme-body-sub-title-color: #777777;

//sidabr main settings
$sidebar-width: 255px;
$sidebar-position: fixed;
$sidebar-background-color: #242934;
$sidebar-shadow: 0 0 11px rgba(143, 164, 232, 0.08);
$sidebar-overflow: auto;
$sidebar-z-index: 9;
$sidebar-transition: 0.3s;

//main header left settings
$main-header-left-bg-color : $transparent-color;
$main-header-padding : 12px;
$main-header-z-index : 9;
$main-header-right-padding: 0 40px;
$main-header-right-toggle-color : $primary-color;
$main-header-right-nav-right :  0px;
$main-header-right-nav-icon-size :  18px;
$main-header-right-nav-icon-color :  $primary-color;
$main-header-right-nav-icon-margin-top :  10px;

//page settings
$page-body-padding : 0 15px;
$page-body-bg-color: $light-color;
$page-body-margin-bottom: 0px;

$page-header-padding : 30px;
$page-title-font-size: 24px;
$page-title-margin-bottom: 0;
$page-title-font-weight: 800;
$page-title-text-tranform: uppercase;
$breadcrumb-size: 14px;
$breadcrumb-content: "/";
$breadcrumb-svg-icon-align: text-top;

//Header settings
$main-header-bg-color: $white;
$main-header-position: fixed;
$main-header-top: 0;
$main-header-z-index: 10;
$main-header-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);

//General tag settings
$body-bg-color: rgba(246, 246, 246, 0.6);
$body-font-size: 14px;
$body-font-color: $theme-body-font-color;
$ul-padding-left: 0px;
$ul-padding-right: 0px;
$ancher-color: $primary-color;
$btn-focus-box-shadow: none;
$all-focus-outline-color: $primary-color;

$paragraph-font-size : 13px;
$paragraph-line-height : 1.7;
$paragraph-letter-spacing : 0.7px;

$code-tag-color: $primary-color;
$code-tag-bg-color: $light-color;
$code-tag-padding: 3px;
$code-tag-margin: 0 3px;
$code-tag-border-radious: 2px;

$blockquote-padding : 15px;
$blockquote-border : 4px solid $light-gray;

$small-tag-padding: 10px;
$small-tag-color: $dark-color;

$pre-tag-bg-color: rgba(68, 102, 242, 0.05);
$pre-tag-padding: 20px 0;

$list-group-active-bg-color: $primary-color;
$list-group-active-border-color: $primary-color;
$list-group-img-size: 40px;
$list-group-margin: 10px;

//footer settings
$footer_bg_color : $white;
$footer_top_bgr_color : $light-color;
$footer_link_align : right;
$footer_dark_color : $dark-color;
$footer_dark__txt_color : $white;


//Card settings
$card-padding        :30px;
$card-margin-bottom  :30px;
$card-border-width   :0px;
$card-border-color   :$light-color;
$card-border-radious :8px;
$card-box-shadow     :1px 5px 24px 0 rgba(68, 102, 242, 0.05);
$card-header-font-weight : 600;
$card-header-bg-color : $white;
$card-header-font-size : 18px;
$card-header-font-transform : capitalize;
$card-header-font-color : $theme-body-font-color;
$card-header-span-size : 12px;
$card-header-span-color : $theme-body-sub-title-color;
$card-body-bg-color : $transparent-color;
$card-footer-bg-color : $white;

//breadcrumb setting
$breadcrumb-ancher-color    : $white;
$breadcrumb-ancher-dark-color    : $black;

//buttons setting
$btn-font-size : 14px;
$btn-padding : 0.6rem 1.75rem;
$btn-lg-font-size: 18px;
$btn-sm-font-size: 12px;
$btn-xs-font-size: 11px;
$btn-xs-padding:  0.05rem 0.4rem;

//error input color start
$light-body-bg-color:#f1f4fb;


//sidebar profile settings
$sidebar-user-section-padding: 25px 10px;
$sidebar-user-section-shadow: 3px 2px 7px -1px rgba(127, 151, 249, 0.13);
$sidebar-profile-name-txt-color : $primary-color;
$sidebar-profile-name-txt-transfer : uppercase;
$sidebar-profile-name-txt-weight : 600;
$sidebar-profile-name-letter-specing : 1.5px;
$sidebar-profile-name-marging : 3px;
$sidebar-profile-sub-title-font-size: 10px;
$sidebar-profile-sub-title-margin: 0px;
$sidebar-profile-img-shadow: 0 0 15px rgba(68, 102, 242, 0.3);

//Sidebar main menu setting
$sidebar-menu-padding: 20px;
$sidebar-menu-list-style: none;
$sidebar-menu-list-margin: 0;

$sidebar-icon-size: 14px;
$sidebar-icon-margin: 14px;
$sidebar-icon-stroke-width: 3px;
$sidebar-font-size: 14px;
$sidebar-letter-specing: 0.5px;
$sidebar-txt-transform: capitalize;
$sidebar-font-weight: 600;
$sidebar-font-color: $white;
$sidebar-padding-top: 12px;
$sidebar-padding-bottom: 12px;
$sidebar-sub-header-padding: 15px;
$sidebar-sub-header-margin: 0;

$sidebar-text-transform: capitalize;
$sidebar-heading-hover-padding: 3px;
$sidebar-hover-txt-color: $primary-color;
$sidebar-arrow-margin-top: 2px;
$sidebar-arrow-size: 15px;
$sidebar-arrow-color: $theme-body-font-color;

$sidebar-open-icon: "\f107";
$sidebar-close-icon:"\f105";
$sidebar-icon-font-family: $font-awesome;